import React from "react";
import { Trans } from "react-i18next";

function Footer() {
	return (
	<div className="airy">
		<div className="row">
			<div className="col-lg">
			<br/>
				<Trans i18nKey="global.app.footer">
				</Trans>
			</div>
				</div>
			</div>
	);
}

export default Footer;