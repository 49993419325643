import React from "react";
import { useTranslation } from "react-i18next";
import "@elastic/react-search-ui-views/lib/styles/styles.css";
import { Link } from "react-router-dom";
import logoImg from './logo.svg';



import { supportedLanguages } from "../config/i18n";
import { languageCodeOnly } from "../services/i18n";
function Navbar({ onLanguageChange }) {
	const { i18n } = useTranslation();
	const changeLanguage = (lng) => {
		i18n.changeLanguage(lng);
	}


	return (
		<nav className="navbar"	>

				<div className="navbar-item">
					<img src={logoImg} alt="OLACSearch Logo" style={{ width: '320px', display: "fluid" }} />
				</div>

				<div className="navbar-item">
					<a href="/" title="OLAC Search">
						<div className="navbar-logo">
							<h1>{i18n.t('global.app.name')}</h1>

						</div>

					</a>
				</div>
				<div className="navbar-item">
					<Link to="/imprint">&nbsp;&nbsp;&nbsp;Imprint&nbsp;&nbsp;&nbsp;</Link>
					<Link to="/privacy">Privacy</Link>
				</div>



		</nav>
	);
}

export default Navbar;