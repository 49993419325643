import React, { useEffect } from "react";

import ElasticsearchAPIConnector from "@elastic/search-ui-elasticsearch-connector";
import { useTranslation } from "react-i18next";
import CustomResultView from "./CustomResultView"
import { MultiCheckboxFacet, SingleSelectFacet, BooleanFacet} from "@elastic/react-search-ui-views";

import "@elastic/react-search-ui-views/lib/styles/styles.css";
import {
	ErrorBoundary,
	Facet,
	SearchProvider,
	SearchBox,
	Results,
	PagingInfo,
	ResultsPerPage,
	Paging,
	Sorting,
	WithSearch,
	withSearch
} from "@elastic/react-search-ui";
import { Layout } from "@elastic/react-search-ui-views";

import ClearFilters from "./ClearFilters";

import {
	buildSearchOptionsFromConfig,
	buildSortOptionsFromConfig,
	getConfig,
} from "./config/config-helper";
const { searchKey, endpointBase, engineName } = getConfig();

const connector = new ElasticsearchAPIConnector({
	host: endpointBase,
	index: engineName,
	apiKey: searchKey
});
const manualACQ = {
	results: {
		search_fields: {
			parks_search_as_you_type: {}
		},
		resultsPerPage: 10,
		initialState: {
			resultsPerPage: 10
		},
		result_fields: {
			title: {
				snippet: {
					size: 100,
					fallback: true
				}
			},
			nps_link: {
				raw: {}
			}
		}
	},
	suggestions: {
		types: {
			documents: {
				fields: ["dc.title.suggest","dc.contributor.suggest","dc.creator.suggest","id"]
			}
		},
		size: 5
	}
}

const facets =	{"dc.type.txt.keyword": {
				"type": "value",
				"size": 30
			},
			"dc.date.txt": {
				"type": "value",
				"size": 30
			},
			"dc.format.txt.keyword": {
				"type": "value",
				"size": 30
			},
		"dc.type.olac.linguistic-type.keyword": {
				"type": "value",
				"size": 40
			},
		"dc.language.olac.language.keyword": {
				"type": "value",
			"size": 5500,
			"minOccur": 1
			},
		"dc.publisher.txt.keyword": {
				"type": "value",
				"size": 30
			},
		"dc.creator.txt.keyword": {
				"type": "value",
				"size": 30
			},
		"dc.creator.olac.role.keyword": {
				"type": "value",
				"size": 30
			},
		"dc.contributor.txt.keyword": {
				"type": "value",
				"size": 30
			},
		"dc.contributor.olac.role.keyword": {
				"type": "value",
				"size": 30
			},
		"dc.subject.txt.keyword": {
				"type": "value",
				"size": 30
			},
		"dc.subject.olac.language.keyword": {
				"type": "value",
				"size": 6000
			},
		"dc.subject.olac.linguistic-field.keyword": {
				"type": "value",
				"size": 30
			},
		"dc.coverage.txt.keyword": {
				"type": "value",
				"size": 30
			},
		"dcterms.spatial.txt.keyword": {
				"type": "value",
				"size": 30
			}
	}
	
;
const config = {
	searchQuery: {
		facets: facets,
		...buildSearchOptionsFromConfig()
	},
	autocompleteQuery: manualACQ,
	apiConnector: connector,
	alwaysSearchOnInitialLoad: true,
	initialState: {
            resultsPerPage: 5
        }

};

export default function App() {

	const { i18n } = useTranslation();
	useEffect(() => {
		document.dir = i18n.dir();
		document.documentElement.lang = i18n.language;
		document.title = i18n.t("global.app.name");
		console.info(document.dir);
	}, [i18n, i18n.language]); return (

		<SearchProvider config={config} connector={connector}  >

			<WithSearch mapContextToProps={({ wasSearched }) => ({ wasSearched })}>
				{({ wasSearched }) => {
					return (
						<ErrorBoundary>
							<Layout
								header={<SearchBox autocompleteSuggestions={true} autocompleteResults={true} searchAsYouType={true} shouldClearFilters={false} />}
								sideContent={
									<div>    
										<ClearFilters />
										<Facet	label="Olac language (the material)" isFilterable="true"  field="dc.language.olac.language.keyword"/>
										<Facet  label="Linguistic field" isFilterable="true" field="dc.subject.olac.linguistic-field.keyword"/>
										<Facet label="Olac language (the resource is about)"	 isFilterable="true" field="dc.subject.olac.language.keyword"/>
										<Facet	filterType="all" label="subject"  isFilterable="true" 	field="dc.subject.txt.keyword"/>
										<Facet label="type"	 isFilterable="true" 	field="dc.type.txt.keyword"/>
										<Facet label="Olac linguistic type"		 isFilterable="true" field="dc.type.olac.linguistic-type.keyword"/>
										<Facet	label="Publisher"	 isFilterable="true" field="dc.publisher.txt.keyword"/>
										<Facet 	filterType="all"  label="Contributor"		 isFilterable="true" field="dc.contributor.txt.keyword"/>
										<Facet 	filterType="any" 	label="Contributor role" isFilterable="true" 	field="dc.contributor.olac.role.keyword"/>
										<Facet label="Coverage"		 isFilterable="true" field="dc.coverage.txt.keyword"/>
										<Facet	label="Creator"	 isFilterable="true" field="dc.creator.txt.keyword"/>
										<Facet 	filterType="any"  label="Creator role"		 isFilterable="true" field="dc.creator.olac.role.keyword"/>
										<Facet	label="Spatial terms"	 isFilterable="true" field="dcterms.spatial.txt.keyword"/>
										<Facet	 label="Format" isFilterable="true" 	field="dc.format.txt.keyword"/>
									</div>
								}
								bodyContent={
									<><Results resultView={CustomResultView}/></>
								}
								bodyHeader={
										<React.Fragment >
										{wasSearched && <PagingInfo
										mapContextToProps={context => {
											console.info(context.totalResults)
                                  if (context.totalResults<10000) return context;
                                  return {
                                    ...context,
                                    totalResults: "more than 10000",
                                  };
                                }}
										  />  }
										{wasSearched && <ResultsPerPage options={[5, 10, 50]} />}
									</React.Fragment>
								}
								bodyFooter={<Paging />}
							/>
						</ErrorBoundary>
					);
				}}


			</WithSearch>
		</SearchProvider>
	);
}
