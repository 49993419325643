import React from 'react';
import Obfuscate from 'react-obfuscate';


function Imprint() {
	return (

		<div className="airy">
			<div className="row">
				<h1>Imprint</h1>

				<div className="row">
								<h6>Published by:</h6>
								<div className="col-lg "><a href="https://elararchive.org/">The Endangered Languages Archive</a><br />
									at the <a href="https://www.bbaw.de/">Berlin-Brandenburg Academy of Sciences and Humanities (BBAW)</a><br />
									Mandana Seyfeddinipur<br />
									Jägerstraße 22/23<br />
									10117 Berlin/Germany
								</div>
								<h6>Represented by:</h6>
								<div className="col-lg">
									Prof. Dr. Dr. h.c. mult. Christoph Markschies<br/>
									Tel.: +49 (0)30 20370-0<br/>
									E-Mail: <Obfuscate email="bbaw@bbaw.de">Contact BBAW</Obfuscate>
								</div>
								<h6>Scientific Curatorship & Webmaster:</h6>
								<div className="col-lg">
									Mandana Seyfeddinipur<br/>
									E-Mail: <Obfuscate email="seyfeddinipur@bbaw.de">Contact Mandana Seyfeddinipur</Obfuscate>
								</div>
								<h6>Legal status:</h6>
								<div className="col-lg">
									Public law legal entity (Rechtsfähige Körperschaft des öffentlichen Rechts)
								</div><br/><br/><br/>
								<h6>VAT Identification No.:</h6>
								<div className="col-lg">
									DE 167 449 058 (according to §27 a of the Value Added Tax Law of the Federal Republic of Germany)
								</div>
							</div>
				<div className="col-lg">
					<h2><br /><Obfuscate email="contact@language-archives.org">Contact us!</Obfuscate></h2>
				</div>
			</div>
		</div>

	);
}

export default Imprint;